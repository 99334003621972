<template>
  <v-card class="ma-2">
    <v-container class="pa-0" style="background-color: white;" fluid>

      <v-row justify="space-between" align="center" no-gutters class="px-3 pt-2 pb-1">
        <v-col class="d-flex justify-start">
          <h5>{{ $t('text.order') }} {{ item.ServiceHistoryOrderReference }}</h5>
        </v-col>
        <v-col class="d-flex justify-end mt-n3 mr-n3">
          <v-card :color="statuscolor(item.Job?.StatusId) + ' lighten-5'" flat
            class="rounded-0 pr-3 pl-5 py-1 text-truncate"
            :class="statuscolor(item.Job?.StatusId) + '--text text--darken-2'" id="status_shape">
            {{ item.Job?.StatusName }}
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="px-3 py-0" align="center">
        <v-col cols="auto">
          <p class="mb-0" style="font-weight: 600; font-size: 12px; opacity: 0.8;">{{ $t('text.type') }}:</p>
        </v-col>
        <v-col>
          <v-chip small class="px-2 mx-2 text-truncate" style="border-radius: 4px;">
            {{ item.Job.JobTypeName }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row v-if="item.Job?.CustomerComment" no-gutters class="px-3 py-1 mt-1" style="background-color: #fafafa;">
        <v-col>
          <p class="mb-0" style="font-weight: 600; font-size: 12px; opacity: 0.8;">{{ $t('text.customer_comment') }}:
          </p>
          <p class="mb-0" style="white-space: pre-wrap;">{{ item.Job?.CustomerComment }}</p>
        </v-col>
      </v-row>

      <v-row v-if="item.InvoiceComment" no-gutters class="px-3 py-2" style="background-color: #edfffd;">
        <v-col>
          <p class="mb-0" style="font-weight: 600; font-size: 12px; opacity: 0.8;">{{ $t('text.invocie_comment') }}:</p>
          <p class="mb-0" style="white-space: pre-wrap;">{{ item.InvoiceComment }}</p>
        </v-col>
      </v-row>

      <v-row v-if="item.MachineServiceHistoriesParts?.length || item.ServiceReport" no-gutters class="pl-2 pr-3 pt-2">
        <v-col v-if="item.MachineServiceHistoriesParts?.length">
          <v-card :flat="!partsexpanded" :color="!partsexpanded ? 'transparent' : ''"
            :class="partsexpanded ? 'mb-n2 pb-2' : ''" class="px-1 pt-1 rounded-b-0"
            @click="item.MachineServiceHistoriesParts?.length ? (partsexpanded = !partsexpanded) : null">
            <v-row no-gutters>
              <v-col>
                {{ $t('text.articles') }}: ({{ item.MachineServiceHistoriesParts?.length ?? 0 }})
              </v-col>
              <v-col cols="auto">
                <v-btn icon small>
                  <v-icon>{{ partsexpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-if="item.ServiceReport" align="end">
          <v-btn :href="'/resource/?relativePath=' + item.ServiceReport" small color="primary" text class="px-0">
            <v-icon size="20">mdi-file-pdf-box</v-icon>
            {{ $t('text.service_report') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="partsexpanded" no-gutters class="px-2 pb-2">
        <v-card v-if="partsexpanded" class="rounded-t-0" width="calc(100vw - 20px)">
          <v-list class="py-0">
            <v-list-item v-for="part in item.MachineServiceHistoriesParts" :key="part.Id" dense
              style="border-bottom: 1px solid rgb(243, 243, 243);">
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ part.ArticleDescription }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ part.Article?.ArticleNumber }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="mx-0">
                x{{ part.ArticleQtyOrdered }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-row>

      <v-divider style="border-color: rgb(0 0 0 / 31%);" />
    </v-container>
  </v-card>
</template>


<script>
export default {
  name: "MachineJobListItem",
  props: {
    item: Object,
  },
  components: {},

  data() {
    return {
      partsexpanded: false,
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
#status_shape {
  clip-path: polygon(0 1%, 100% 0, 100% 100%, 12px 100%);
}
</style>